import React from 'react';
import styled from 'styled-components'
import ScrollTo from 'scroll-to-element'

import { Grid, Button, Typography, Container } from '@material-ui/core';


const RegisterButton = styled(Button)`
    padding-left: 42px;
    padding-right: 42px;
    margin-top: 12px;
    height: 50px;
    @media screen and (max-width: 960px) {
        height: 75px;
    }
`

const Video = () => {
    
    const handleClick = () => {
        ScrollTo('#register', {
            duration: 500
        })
    }

    return (
        <Grid container style={{ marginTop: 42, marginBottom: 42 }}>
            <Container maxWidth="lg">
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                        <Typography variant="h2" align="center" className="text-bold dark--text" style={{ fontSize: 32 }} paragraph>
                            Entenda como obter reconhecimento e relacionamento de sua marca e de seu produto utilizando o Big Tester
                        </Typography>
                        <RegisterButton
                            size="large"
                            className="success"
                            style={{ paddingLeft: 42, paddingRight: 42, marginTop: 12 }}
                            onClick={handleClick}>
                            Quero anunciar no Big Tester!
                        </RegisterButton>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ position: 'relative' }}>
                        <iframe
                            title="Como funciona o Big Tester"
                            width="600"
                            height="338"
                            src="https://www.youtube.com/embed/4ChMDRjJ9q8"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{ maxWidth: '100%' }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
}

export default Video;