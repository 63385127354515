import React from 'react';
import styled from 'styled-components'

import { Grid, Container } from '@material-ui/core';

import Header from './Header';

import BackgroundImage from '../images/destaque2.jpg'

const TopPageContainer = styled(Grid)`
    min-height: 40vh;
    background-color: darkblue;
    background-image: url(${BackgroundImage});
    background-size: contain;
    @media screen and (max-width: 960px) {
        min-height: 65vh;
    }
`

const TopPage = () => {
    const phases = [
        <span>Quer aumentar suas <span className="secondary--text">vendas</span>?</span>,
        <span>Quer tornar sua marca <span className="secondary--text">conhecida</span>?</span>,
        <span>Quer <span className="secondary--text">divulgar</span> seu produto?</span>,
        <span>Quer <span className="secondary--text">ganhar</span> seguidores?</span>
    ]
    const [phaseCoutner, setPhraseCounter] = React.useState(0)

    const nextPhrase = () => {
        setTimeout(() => {
            if (phaseCoutner === phases.length - 1) setPhraseCounter(0)
            else setPhraseCounter(phaseCoutner + 1)
        }, 2000);
    }

    return (
        <TopPageContainer container alignItems="center">
            <Container maxWidth="lg" style={{ position: 'relative', height: '100%' }}>
                <Header />
                <Grid container alignItems="center" style={{ height: '100%' }}>
                    <Grid item xs={12} style={{ marginTop: 36, display: 'flex', justifyContent: 'center' }}>
                        <div id="top-page-title" className="typewriter">
                            {phases.map((phase, index) => (index === phaseCoutner ?
                                <h1
                                    key={`phase-${index}`}
                                    className="text-bold white--text"
                                    style={{ fontSize: 48, display: 'inline-flex' }}
                                    align="center"
                                    onAnimationEnd={() => nextPhrase()}>
                                    {phase}
                                </h1>
                                : '')
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </TopPageContainer>
    );
}

export default TopPage;