import React from 'react';
import { Grid } from '@material-ui/core';
import Register from './components/Register';
import Video from './components/Video';
import Offer from './components/Offer';
import Connect from './components/Connect';
import Footer from './components/Footer';
import TopPage from './components/TopPage';

function App() {
  return (
    <Grid container>
      <TopPage />
      <Video />
      <Connect />
      <Offer />
      <Register />
      <Footer />
    </Grid>
  );
}

export default App;
