import React from "react";
import useForm from "react-hook-form";
import styled from "styled-components";
import Sendy from "../helpers/sendy";
import TagManager from "react-gtm-module";

import {
  Grid,
  Container,
  Card,
  CardContent,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import InputMask from "react-input-mask";

import DestaqueImage from "../images/destaque.jpg";

const RegisterContainer = styled(Grid)`
  background-image: url(${DestaqueImage});
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: bottom center;
  min-height: 70vh;
  background-color: #a0e7f9;
  @media screen and (max-width: 960px) {
    padding-top: 24px;
    padding-bottom: 32px;
    background-size: auto 100% !important;
    background-position: bottom left 40% !important;
  }
  .register-card {
    margin-bottom: 32px;
    @media screen and (max-width: 960px) {
      margin-top: 32px;
    }
  }
`;
const tagManagerArgs = {
  gtmId: "GTM-5WBC2C",
};

TagManager.initialize(tagManagerArgs);

const RegisterInput = styled(TextField)`
  background-color: white;
  margin-bottom: 4px !important;
`;

const Register = () => {
  const { register, handleSubmit, errors } = useForm();
  const [flip, setFlip] = React.useState(false);

  const handleSave = (data) => {
    Sendy.subscriber(data);
    setFlip(true);
  };

  const BackCard = () => (
    <CardContent className="flip-card-back dark--text">
      <Grid container style={{ height: "100%", alignItems: "center" }}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center" paragraph>
            Obrigado por se cadastarar!
          </Typography>
          <Typography variant="body2" align="center">
            Entraremos em contato em breve.
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  );

  return (
    <RegisterContainer id="register" container>
      <Container maxWidth="lg">
        <Grid container style={{ justifyContent: "flex-end", height: "100%" }}>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Card
              className={`flip-card register-card dark--text ${
                flip ? "flip" : ""
              }`}
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                marginTop: 32,
              }}
            >
              {flip ? (
                <BackCard />
              ) : (
                <CardContent>
                  <form onSubmit={handleSubmit(handleSave)}>
                    <Typography variant="h5" paragraph align="center">
                      Anuncie sua marca no <b>Big Tester</b> e comece a trilhar
                      o caminho para o sucesso de sua empresa
                    </Typography>
                    <RegisterInput
                      placeholder="Nome"
                      fullWidth
                      variant="outlined"
                      style={{ marginBottom: 16 }}
                      inputProps={{ name: "name" }}
                      inputRef={register({
                        required: true,
                      })}
                    />
                    <Typography
                      variant="body2"
                      paragraph
                      style={{ paddingLeft: 8 }}
                    >
                      {errors.name && "Campo inválido"}
                    </Typography>
                    <RegisterInput
                      placeholder="E-mail"
                      fullWidth
                      variant="outlined"
                      style={{ marginBottom: 16 }}
                      inputProps={{ name: "email" }}
                      inputRef={register({
                        required: true,
                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />
                    <Typography
                      variant="body2"
                      paragraph
                      style={{ paddingLeft: 8 }}
                    >
                      {errors.email && "Campo inválido"}
                    </Typography>
                    <RegisterInput
                      placeholder="Empresa"
                      fullWidth
                      variant="outlined"
                      style={{ marginBottom: 16 }}
                      inputProps={{ name: "company" }}
                      inputRef={register({
                        required: true,
                      })}
                    />
                    <Typography
                      variant="body2"
                      paragraph
                      style={{ paddingLeft: 8 }}
                    >
                      {errors.company && "Campo inválido"}
                    </Typography>
                    <InputMask mask="(99) 99999-9999">
                      {(inputProps) => (
                        <RegisterInput
                          placeholder="Telefone"
                          fullWidth
                          variant="outlined"
                          style={{ marginBottom: 16 }}
                          inputProps={{ name: "phone" }}
                          inputRef={register({
                            required: true,
                            pattern: /\([1-9][1-9]\) 9?[0-9]{4}-?[0-9]{4}/,
                          })}
                        />
                      )}
                    </InputMask>
                    <Typography
                      variant="body2"
                      paragraph
                      style={{ paddingLeft: 8 }}
                    >
                      {errors.phone && "Campo inválido"}
                    </Typography>
                    <RegisterInput
                      placeholder="Mensagem"
                      multiline
                      rows={4}
                      fullWidth
                      variant="outlined"
                      style={{ marginBottom: 16 }}
                      inputProps={{ name: "message" }}
                      inputRef={register({
                        required: true,
                        min: 10,
                      })}
                    />
                    <Typography
                      variant="body2"
                      paragraph
                      style={{ paddingLeft: 8 }}
                    >
                      {errors.message && "Campo inválido"}
                    </Typography>
                    <Button
                      fullWidth
                      size="large"
                      variant="contained"
                      className="success"
                      type="submit"
                    >
                      Quero anunciar no Big Tester!
                    </Button>
                  </form>
                </CardContent>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </RegisterContainer>
  );
};

export default Register;
