import React from 'react';
import ScrollTo from 'scroll-to-element'

import styled from 'styled-components'
import { Grid, Typography, Container, Button, Box } from '@material-ui/core';

import v1 from '../images/v1.png'
import v2 from '../images/v2.png'
import v3 from '../images/v3.png'

const options = [
    {
        title: 'PERFORMANCE',
        description: 'Impacte clusters específicos de usuários visando recrutá-los e engajá-los para se tornarem consumidores da sua marca.',
        image: v1
    },
    {
        title: 'PROVA SOCIAL',
        description: 'Gere experimentação e testemunho de seus produtos pelos usuários selecionados de acordo com o foco da sua marca',
        image: v2
    },
    {
        title: 'BRANDING',
        description: 'Posicione corretamente sua marca para que a mesma tenha percepção ideal pelos impactados.',
        image: v3
    }
]

const GridText = styled(Grid)`
    @media screen and (max-width: 960px) {
        text-align: center !important;
    }
`

const Offer = () => {

    const handleClick = () => {
        ScrollTo('#register', {
            duration: 500
        })
    }

    const Item = ({ item }) => {
        return (
            <Grid container className="dark--text" style={{ textAlign: 'center' }}>
                <Grid item xs={12}>
                    <img src={item.image} alt={item.title} height="100px" style={{ marginBottom: 16 }} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" className="text-bold" paragraph>
                        {item.title}
                    </Typography>
                    <Typography variant="body1" className="light--text" paragraph>
                        {item.description}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <Container maxWidth="lg" className="dark--text" style={{ marginTop: 42, marginBottom: 42 }}>
            <Grid container spacing={4} style={{ alignItems: 'center' }}>
                <GridText item xs={12} md={5}>
                    <Typography variant="h3" className="text-bold" paragraph>
                        Visibilidade para sua marca
                    </Typography>
                    <Typography variant="body2" className="light--text" paragraph>
                        Com um investimento justo, o <b>Big Tester</b> é capaz de construir todas as etapas de <b>relacionamento entre milhares de usuários e sua marca</b>, influenciando na <b>decisão de compra de seus novos clientes</b>.
                    </Typography>
                    <Typography variant="body2" className="light--text" paragraph>
                        <b>Supreenda-se</b> com alguns números que somente uma <b>plataforma confiável, robusta e de alto impacto</b> pode oferecer ao seus negócio, ao seu produto e à sua marca:
                    </Typography>
                    <Box>
                        <ul className="light--text text-bold">
                            <li style={{ marginBottom: 8 }}>+ 6 milhões de pessoas cadastradas</li>
                            <li style={{ marginBottom: 8 }}>400 mill novos usuários por mês</li>
                            <li style={{ marginBottom: 8 }}>40 mil acessos diários</li>
                            <li style={{ marginBottom: 8 }}>+ 130 mil indicações só em 2019</li>
                        </ul>
                    </Box>
                    <Typography variant="body2" className="light--text" paragraph>
                        Usuários que indicam o Big Tester para suas amigos ganham recompensas. Já possuímos diversos influenciadores e embaixadores.
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        className="success"
                        style={{ paddingLeft: 42, paddingRight: 42 }}
                        onClick={handleClick}>
                        Quero anunciar no Big Tester!
                    </Button>
                </GridText>
                <Grid item xs={12} md={7}>
                    <Grid container justify="center" spacing={2}>
                        {options.map((option, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <Item item={option} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Offer;