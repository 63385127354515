import React from 'react';
import { Grid, Container, Typography } from '@material-ui/core'
import styled from 'styled-components'

import BtLogo from '../images/bt_logo.png';

import { Facebook, Twitter, Instagram, Pinterest } from '../icons'

const GridContainer = styled(Grid)`
    background-color: #78909C;
    color: white;
    overflow: hidden;
    position: relative;
    margin: unset;
    text-align: center;
    z-index: 10;
    @media screen and (min-width: 960px) {
        text-align: left;
    }
`
const BorderGrid = styled(Grid)`
    border-left: 1px solid white;
    padding-left: 32px;
    min-height: 150px;
    @media screen and (max-width: 960px) {
        border-left: unset !important;
    }
`

const Footer = () => {
    return (
        <GridContainer container>
            <Container maxWidth="lg">
                <Grid container spacing={3} style={{ padding: 24 }} justify="center">
                    <Grid item xs={12} style={{ textAlign: 'center', marginBottom: 32 }}>
                        <img src={BtLogo} height="30px" alt="Big Tester" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="body2" className="text-bold">
                            Curta nossas redes sociais
                        </Typography>
                        <Facebook style={{ margin: 8, height: 35 }} />
                        <Instagram style={{ margin: 8, height: 35 }} />
                        <Pinterest style={{ margin: 8, height: 35 }} />
                        <Twitter style={{ margin: 8, height: 35 }} />
                    </Grid>
                    <BorderGrid item xs={12} md={4}>
                        <Typography variant="body2" className="text-bold" style={{ marginBottom: 8 }}>
                            Política de privacidade | FAQ
                        </Typography>
                        <Typography variant="caption">
                            ©Adclick Ltda - Todos os direitos reservados.
                            <br></br>
                            Este site é de propriedade da Adclick Ltd, única responsável por seu conteúdo, e destina-se a residentes do Brasil.
                        </Typography>
                    </BorderGrid>
                    <BorderGrid item xs={12} md={5}>
                        <Typography variant="caption">
                            Imagens meramente ilustrativas. O BigTester é responsável pela distribuição de 100% dos produtos ofertados no site. A compra e distribuição dos prêmios são de única e exclusiva responsabilidade do Big Tester. Os fabricantes e/ou distribuidores dos produtos ofertados como prêmios não organizam e não participam da política comercial do site. Participe gratuitamente, acumule pontos e troque seus pontos pelos produtos ofertados.
                        </Typography>
                    </BorderGrid>
                </Grid>
            </Container>
        </GridContainer>
    );
}

export default Footer;