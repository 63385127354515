import React from 'react';

import styled from 'styled-components'
import { Grid, Hidden, Button, IconButton, Icon } from '@material-ui/core'

import BtLogo from '../images/logo.png'

const LogoImg = styled.img`
    width: 200px;
    @media screen and (max-width: 600px) {
        max-width: 50vw;
    }
`

const SiteHeader = styled(Grid)`
    min-height: 75px;
    @media screen and (max-width: 960px) {
        min-height: 50px !important;
    }
`

const HeaderContainer = styled(Grid)`
    position: absolute;
    top: 16px;
    width: 95% !important;
`

const Header = () => {

    const handleClick = () => {
        window.location = 'https://www.bigtester.com.br/'
    }

    return (
        <HeaderContainer container>
            <SiteHeader item xs={12}>
                <Grid container style={{ height: '100%', alignItems: 'center' }}>
                    <Grid item style={{ flex: 1 }}>
                        <LogoImg src={BtLogo} alt="Big Tester" />
                    </Grid>
                    <Grid item>
                        <Hidden smDown>
                            <Button variant="text" className="white--text" style={{ textTransform: 'none' }} onClick={handleClick}>
                                Ir para o site
                            </Button>
                        </Hidden>
                        <Hidden mdUp>
                            <IconButton className="primary" size="small" style={{ padding: 8, marginRight: 12 }} onClick={handleClick}>
                                <Icon>home</Icon>
                            </IconButton>
                        </Hidden>
                    </Grid>
                </Grid>
            </SiteHeader>
        </HeaderContainer>
    );
}

export default Header;