import axios from "axios";

class SendyAPI {
  constructor() {
    this.urlSendy = "https://empresas.bigtester.com.br/api/";
    this.list = "jSu3I892pdRwNBJLlLjRyi3g";
    this.defaultAxios = axios.create({
      baseURL: this.urlSendy,
    });
  }

  async subscriber(data) {
    data.list = this.list;
    console.log(data);
    try {
      return await this.defaultAxios.post("lead", data);
      // return await this.defaultAxios.post("subscribe", data);
    } catch (error) {
      return error;
    }
  }
}

export default new SendyAPI();
