import React from 'react';
import ScrollTo from 'scroll-to-element'

import styled from 'styled-components'
import { Container, Grid, Typography, Hidden, Button } from '@material-ui/core';

import ConnectImage from '../images/conecte.png'
import ConnectImageMobile from '../images/conecte-mobile.png'

const GridText = styled(Grid)`
    @media screen and (max-width: 960px) {
        text-align: center !important;
    }
`

const Connect = () => {

    const handleClick = () => {
        ScrollTo('#register', {
            duration: 500
        })
    }

    return (
        <Container maxWidth="lg" className="dark--text" style={{ marginBottom: 42 }}>
            <Grid container>
                <GridText item xs={12}>
                    <Typography variant="h3" className="text-bold" paragraph>
                        O Big Tester conecta seu produto ou seu serviço com seus potenciais clientes!
                    </Typography>
                    <Typography variant="body1" className="light--text">
                        O Big Tester foi pensado para conectar uma comunidade de testadores com produtos diversos. Desta maneira, o Big Tester consegue criar uma experiência rica entre os usuários da plataforma e as marcas, trazendo para sua empresa ações customizadas para geração de experimentação com engajamento social, possibilidade da marca se posicionar corretamente, captação de leads segmentados, insights através de pesquisas de comportamentos de consumo, geração de tráfego para seu site e finalmente as possíveis vendas!
                    </Typography>
                </GridText>
                <Grid item xs={12} style={{ marginTop: 32, marginBottom: 32 }}>
                    <Hidden smDown>
                        <img src={ConnectImage} alt="Conetando você ao cliente" />
                    </Hidden>
                    <Hidden mdUp>
                        <img src={ConnectImageMobile} alt="Conetando você ao cliente" style={{ maxWidth: '90vw' }} />
                    </Hidden>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        className="success"
                        size="large"
                        style={{ padding: 16, paddingLeft: 42, paddingRight: 42 }}
                        onClick={handleClick}>
                        Quero anunciar no Big Tester!
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Connect;